import React from 'react'
import styled from 'styled-components/macro'
import get from 'lodash.get'
import set from 'lodash.set'
import { cloneDeep } from 'lodash';

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import axios from 'axios'

const ModalFormLabel = styled.span`
  font-weight: bold;
`

/**
 * @param {Object} lookupData - data to be sent to SmartyStreets
 * format: inputId, addressee, street, street2, secondary, urbanization, city, state, zipcode, maxCandidates, match
 * see: https://github.com/smartystreets/smartystreets-javascript-sdk/blob/master/examples/us_street.js
 */
export const checkAddress = async (lookupData) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/address-validations`,
      lookupData,
      { headers: { Accept: '*/*' } }
    )
    return data
  } catch (err) {
    return err
  }
}

export const generateAddressModalProps = async ({
  values = {},
  addressKeys = {},
  onContinue,
  onCancel,
}) => {
  const generateModalContent = (addressValues, hasError = false, isLast = false) => {
    return (
      <>
        {hasError && <Alert severity="warning" sx={{ mb: 2 }}>Address could not be found.</Alert>}
        <Stack>
          <Typography>
            <ModalFormLabel>Street: </ModalFormLabel>
            {get(addressValues, addressKeys.address)}
            {` ${get(addressValues, addressKeys.addressalt) ?? ''}`}
          </Typography>
          <Typography>
            <ModalFormLabel>City: </ModalFormLabel>
            {get(addressValues, addressKeys.city)}
          </Typography>
          <Typography>
            <ModalFormLabel>State / Province: </ModalFormLabel>
            {get(addressValues, addressKeys.state)}
          </Typography>
          <Typography>
            <ModalFormLabel>Zip / Postal Code: </ModalFormLabel>
            {get(addressValues, addressKeys.zip)}
          </Typography>
          <Typography>
            <ModalFormLabel>Country: </ModalFormLabel>
            {get(addressValues, addressKeys.country)}
          </Typography>
        </Stack>
        {!isLast && <Divider sx={{ my: 2 }} />}
      </>
    )
  }

  let modalContent = [];

  const vals = cloneDeep(Array.isArray(values) ? values : [values])
  let validCheck = []

  for (let i = 0; i < vals.length; i += 1) {
    const val = vals[i];

    const response = await checkAddress({
      line1: get(val, addressKeys.address),
      line2: get(val, addressKeys.addressalt),
      city: get(val, addressKeys.city),
      state: get(val, addressKeys.state),
      zip: get(val, addressKeys.zip),
      country: get(val, addressKeys.country),
    })

    if (response instanceof Error) {
      const modalProps = {
        title: 'The selected address could not be validated, please check your input and try again',
        size: 'sm',
        footerButtonProps: [
          {
            children: 'Repair Address',
            color: 'primary',
            variant: 'outlined',
            onClick: () => onCancel(),
          },
        ]
      };

      if (response?.response?.status === 400) {
        // safe error
        if (response?.response?.data?.display_message) {
          modalProps.children = (
            <Box>
              <Typography mb={6}>
                {response?.response?.data?.display_message}
              </Typography>
            </Box>
          );
        }
      }

      return { modalProps };
    }

    const { address, is_valid: isValid } = response

    validCheck.push(isValid);

    if (isValid) {
      set(val, addressKeys.address, address.line1)
      set(val, addressKeys.addressalt, address.line2)
      set(val, addressKeys.city, address.city)
      set(val, addressKeys.state, address.state)
      set(val, addressKeys.zip, address.zip)
      set(val, addressKeys.country, address.country)
    }

    modalContent.push(generateModalContent(val, !isValid, i === vals.length - 1))
  }

  const isMultiple = vals.length > 1
  const isValid = validCheck.filter((v) => v).length === vals.length

  const modalProps = {
    title: isMultiple ? 'One or more of the selected addresses could not be found.' : 'The selected address could not be found.',
    size: isMultiple ? undefined : 'sm',
    height: isMultiple ? '90vh' : undefined,
    width: isMultiple ? '414px' : undefined,
    children: modalContent,
    footerButtonProps: [
      {
        children: <Box sx={{ fontSize: 12 }}>Continue anyways</Box>,
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          onContinue(vals)
        },
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: () => onCancel(),
      },
    ],
  }

  if (isValid) {
    modalProps.title = isMultiple ? 'Are the following addresses correct?' : 'Is the following address correct?'
    modalProps.footerButtonProps[0].children = 'Continue'
  }

  return { modalProps }
}
