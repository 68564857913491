import React, { useCallback } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'

import Box from '@mui/material/Box'

import LoadingModal from 'components/common/LoadingModal'

import axios from 'utils/axios'

import useNotification from 'hooks/context/useNotification'
import useConfiguration from 'hooks/useConfiguration'
import useModal from 'hooks/context/useModal'

const useOrderSummary = ({ generateFormData }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    setModalProps,
    setComponent: setModalComponent,
    setOpen: setModalOpen,
  } = useModal()

  const { canAccessModule, instanceConfigurations } = useConfiguration()

  const canAccessDocApproval = canAccessModule(
    '/command-center/document-approval'
  )

  // value coming from api is string as stored in mpx, false is '0'
  const collateralReviewEnabled =
    instanceConfigurations?.collateral_enable_review !== '0'

  const { setError, setBasicNotification } = useNotification()

  const handleFormDataSubmit = async (formData) => {
    await axios.post('/collateral-order-drafts', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const res = await axios.post('/collateral-orders', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    const { letters } = res.data._embedded

    return letters
  }

  const handleSubmit = useCallback(
    async (cart, values) => {
      setModalProps({
        size: 'sm',
        hideButtonsBorder: true,
        title: 'Your order is processing...',
      })
      setModalComponent(LoadingModal)
      setModalOpen(true)

      let letters = []
      let isEmailDelivery = null
      let isMailDelivery = null

      for (let i = 0; i < values.length; i++) {
        try {
          const val = values[i]
          const formData = generateFormData(
            { ...location.state.values[i], ...val },
            cart,
            {},
            false
          )

          if (isEmailDelivery === null && formData.get('is_email_delivery')) {
            isEmailDelivery = true
          } else if (isMailDelivery === null && formData.get('is_mail_delivery')) {
            isMailDelivery = true
          }

          const createdLetters = await handleFormDataSubmit(formData)

          letters = letters.concat(createdLetters)
        } catch (err) {
          setModalOpen(false)
          const errorMsg = err.response?.data?.display_message || 'Error submitting order'

          if (values.length > 1) {
            setError(`Failed on Address #${i}: ${errorMsg}`)
          } else {
            setError(errorMsg)
          }

          return
        }
      }

      const plural = letters?.length > 1

      setBasicNotification(
        <>
          Thank you for submitting the order.{' '}
          {isEmailDelivery && (
            <> The email will be queued and sent shortly.</>
          )}
          {isMailDelivery && letters?.length > 0 && (
            <>
              {collateralReviewEnabled ? (
                <>
                  <Box>
                    The letter{plural && 's'} that will be sent{' '}
                    {plural ? 'have' : 'has'} the id
                    {plural && 's'} of{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {letters.join(', ')}
                    </Box>
                    .
                  </Box>
                  {canAccessDocApproval && (
                    <Box>
                      Check{' '}
                      <Link to="/command-center/document-approval">
                        Doc Approval
                      </Link>
                      .
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {letters.length} document{plural ? 's have' : ' has'} been
                  successfully queued for mailing.
                </>
              )}
            </>
          )}
        </>
      )

      setModalOpen(false)

      navigate('/correspondence-hub/collateral')
    },
    [handleFormDataSubmit, location, generateFormData]
  )

  return {
    handleSubmit,
  }
}

export default useOrderSummary
