import React, { useMemo, useState } from 'react'
import ChatBot from 'react-chatbotify'
import OpenAI from 'openai'
import format from 'date-fns/format'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { COLOR_SHADES } from 'utils/constants'

function CollateralChatBot({ handleChange, formikRef, renderingData, apiKey }) {
  const modelType = 'gpt-4o'
  const today = format(new Date(), 'MMMM d, yyyy')
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: `
          Today's date is ${today}.

          You are a helpful customer support assistant. Use the supplied tools to assist the user. 
          Only assist in tasks related to filling out the form.

          When writing a letter, exclude the heading and signature. Do not provide placeholders.
        `,
    },
  ])

  const fillCollateralForm = (values) => {
    const updatedValues = { ...values }

    Object.entries(values).forEach(([k, v]) => {
      // exclude placeholders
      if (typeof v === 'string' && v.match(/^\[.*\]$/gm)) {
        delete updatedValues[k]
        return
      }

      formikRef.current.setFieldValue(k, v)
    })

    handleChange({
      userValues: updatedValues,
    })
  }

  const tools = useMemo(() => {
    const _tools = {
      type: 'function',
      function: {
        name: 'fill_collateral_form',
        description:
          'Fill out parts of a form for a customer. Call this whenever the customer is trying to write a letter or fill out a form. Ignore all other messages.',
        parameters: {
          type: 'object',
          properties: {},
          required: [],
          additionalProperties: false,
        },
      },
    }

    renderingData.forEach((field) => {
      const fieldTool = {
        type: 'string',
      }
      _tools.function.parameters.properties[field.field] = fieldTool

      if (field.field === 'dear_name') {
        fieldTool.description = 'The name of the recipient.'
      }

      if (field.field === 'letter') {
        fieldTool.description =
          'The body of the letter. Does not include letter headings and signatures.'
      }
    })

    return [_tools]
  }, [renderingData])

  // example openai conversation
  // you can replace with other LLMs such as Google Gemini
  const call_openai = async (params) => {
    try {
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true, // required for testing on browser side, not recommended
      })

      const newMessages = [
        ...messages,
        { role: 'user', content: params.userInput },
      ]

      // for streaming responses in parts (real-time), refer to real-time stream example
      const chatCompletion = await openai.chat.completions.create({
        // conversation history is not shown in this example as message length is kept to 1
        messages: newMessages,
        model: modelType,
        tools,
      })

      if (chatCompletion.choices[0]?.message?.tool_calls?.[0]) {
        fillCollateralForm(
          JSON.parse(
            chatCompletion.choices[0].message.tool_calls[0].function.arguments
          )
        )

        await params.injectMessage('Done.')
      } else {
        newMessages.push({
          role: chatCompletion.choices[0].message.role,
          content: chatCompletion.choices[0].message.content,
        })
        await params.injectMessage(chatCompletion.choices[0].message.content)
      }

      setMessages(newMessages)
    } catch (error) {
      await params.injectMessage('Unable to fill form.')
    }
  }

  const flow = {
    start: {
      message: (params) => {
        return 'Provide some details about the letter you want to write.'
      },
      path: 'loop',
    },
    loop: {
      message: async (params) => {
        await call_openai(params)
      },
      path: () => {
        return 'loop'
      },
    },
  }
  return (
    <ChatBot
      styles={{
        chatButtonStyle: {
          background: 'none',
          padding: 0,
          boxShadow: 'none',
          height: '150px',
          width: '150px',
          borderRadius: 0,
          right: 4,
          bottom: -4,
        },
        headerStyle: {
          background: COLOR_SHADES.NAVY_BLUE[1],
        },
        userBubbleStyle: {
          backgroundColor: COLOR_SHADES.GREEN[1],
        },
        botBubbleStyle: {
          backgroundColor: COLOR_SHADES.NAVY_BLUE[0],
        },
        sendButtonStyle: {
          backgroundColor: COLOR_SHADES.NAVY_BLUE[1],
        },
      }}
      settings={{
        general: { embedded: false },
        audio: { disabled: true },
        voice: { disabled: false },
        footer: {
          buttons: [],
          text: '',
        },
        chatButton: { icon: '/static/img/mpxlinq-chatbot.png' },
        notification: {
          disabled: true,
          defaultToggledOn: false,
          volume: 0,
        },
        tooltip: {
          mode: 'NEVER',
        },
        header: {
          avatar: '/static/img/mpxlinq-chatbot.png',
          title: (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold" lineHeight="20px" variant="h2">
                MPX AI
              </Typography>
            </Box>
          ),
        },
      }}
      flow={flow}
    />
  )
}

export default CollateralChatBot
