import React, { useRef, useState, useMemo } from 'react'

import Modal from 'components/common/Modal'
import FormRenderer from 'components/common/FormRenderer'

import useNotification from 'hooks/context/useNotification'
import { parsePrintAssetRules } from 'utils/rules'
import { PRINT_ASSET_MIME_TYPES } from 'utils/constants'

function UploadNewVersionModalContent({
  asset,
  setLoading,
  formikRef,
  addNewPrintAssetVersion,
  setOpen,
}) {
  const [isActiveCollateral, setIsActiveCollateral] = useState(Boolean(false))
  const { setBasicNotification, setError } = useNotification()

  const handleSubmit = async (values) => {
    setLoading(true)

    try {
      const formDataValues = {}

      Object.entries(values).forEach(([key, value]) => {
        if (key.includes('custom_data')) {
          if (!formDataValues['custom_field_data']) {
            formDataValues['custom_field_data'] = {}
          }

          if (Array.isArray(value))
            value.forEach(({ name, value }) => {
              formDataValues['custom_field_data'][name] = value
            })
        } else {
          if (key === 'name' && !values.name?.trim?.() && values.assetfile) {
            formDataValues[key] = values.assetfile.name
          } else if (key === 'set_as_default') {
            formDataValues[key] = values.set_as_default ? 1 : 0
          } else if (key === 'set_as_debug') {
            formDataValues[key] = values.set_as_debug ? 1 : 0
          } else {
            formDataValues[key] = value
          }
        }
      })

      await addNewPrintAssetVersion({
        id: asset?.id,
        updateData: { ...formDataValues },
      })

      setBasicNotification('New Version has been successfully added!')
      setOpen(false)
    } catch (err) {
      setError(
        err?.response?.data?.display_message ||
          err?.message ||
          'Error updating asset'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    if (typeof e?.target == 'object' && e.target.name === 'set_as_default') {
      // clear assetfile if not valid
      if (
        e.target.value &&
        !formikRef.current?.values?.assetfile?.type?.includes('image')
      ) {
        formikRef.current.setFieldValue('assetfile', null)
      }

      setIsActiveCollateral(
        e.target.value && Boolean(asset?.collateral_available)
      )
    }
  }

  const renderingData = useMemo(() => {
    return [
      {
        field: 'assetfile',
        data_type: 'fileupload',
        placeholder: 'Drag and drop files here',
        includePreview: false,
        multiple: true,
        accept: parsePrintAssetRules(
          asset?._embedded?.rules || {},
          isActiveCollateral
        ).join(', '),
        acceptLabel: isActiveCollateral
          ? 'PNG, JPEG, TIFF, PDF'
          : Object.keys(PRINT_ASSET_MIME_TYPES).join(', '),
        rules: asset?._embedded?.rules || {},
      },
      {
        field: 'set_as_default',
        data_type: 'checkbox',
        display: 'Set as active',
      },
      {
        field: 'set_as_debug',
        data_type: 'checkbox',
        display: 'Set as debug',
      },
    ]
  }, [asset, isActiveCollateral])

  return (
    <FormRenderer
      renderingData={renderingData}
      innerRef={formikRef}
      handleSubmit={handleSubmit}
      onChange={handleChange}
    />
  )
}

function UploadNewVersionModal({
  open,
  setOpen,
  asset,
  addNewPrintAssetVersion,
}) {
  const formikRef = useRef()
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      hideButtonsBorder
      title={`Upload New Version of ${asset?.name || ''}`}
      width="732px"
      height="605px"
      open={open}
      footerButtonProps={[
        {
          children: 'Upload',
          variant: 'contained',
          color: 'primary',
          size: 'action-header',
          onClick: () => formikRef.current.submitForm(),
          loading: loading,
        },
        {
          children: 'Cancel',
          variant: 'outlined',
          color: 'primary',
          size: 'action-header',
          disabled: loading,
          onClick: () => setOpen(false),
        },
      ]}
    >
      <UploadNewVersionModalContent
        asset={asset}
        setLoading={setLoading}
        formikRef={formikRef}
        addNewPrintAssetVersion={addNewPrintAssetVersion}
        setOpen={setOpen}
      />
    </Modal>
  )
}

export default UploadNewVersionModal
