import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import useCollateralContext from 'hooks/collateral/useCollateralContext'
import deepEqual from 'deep-equal'
import format from 'date-fns/format'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import Categories from './Categories'
import ActiveTemplatePreview from './ActiveTemplatePreview'
import OrderList from './OrderList'
import OrderSummaryModal from './OrderSummaryModal'

import generateKey from 'helpers/node/generateKey'

import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'
import isEmptyObject from 'helpers/node/isEmptyObject'
import {
  COLLATERAL_FIELD_TYPES,
  COLLATERAL_MODES,
  DELIVERY_TYPES,
} from 'utils/constants'

function ItemsOrderList() {
  const location = useLocation()
  const blockTimeout = useRef(false)
  const [cart, setCart] = useState([])
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [previewFormData, setPreviewFormData] = useState(null)
  const [previewFormTimeout, setPreviewFormTimeout] = useState(0)
  const [pdfValues, setPdfValues] = useState(null)
  const { setOpen: setModalOpen, setModalProps } = useModal()
  const { setBasicNotification } = useNotification()
  const {
    activeTemplate,
    setActiveTemplate,
    fetchPreviewPdf,
    pdfLoading,
    setPdfLoading,
    mode,
  } = useCollateralContext()

  const parseCart = useCallback(
    (values) => {
      let key = generateKey('cart-item')
      let foundIndex = -1

      if (activeTemplate.id) {
        foundIndex = cart.findIndex(
          (item) => item.template.id === activeTemplate.id
        )

        if (foundIndex > -1) {
          key = cart[foundIndex].key
        }
      }

      const template = {
        ...activeTemplate,
        key,
        fields: activeTemplate.fields.map((field) => ({
          ...field,
          default_value: values[field.path],
        })),
      }

      const newCart = [...cart]

      if (foundIndex > -1) {
        newCart[foundIndex] = {
          template,
          key,
        }
      } else {
        newCart.push({
          template,
          key,
        })
      }

      setActiveTemplate(template)

      return newCart
    },
    [activeTemplate, cart]
  )

  const addToCart = useCallback(
    (values) => {
      setCart(parseCart(values))
    },
    [parseCart, setCart]
  )

  const removeFromCart = useCallback(
    (index) => {
      const modalProps = {
        title: 'Remove item from order?',
        children: `${cart[index].template.name} will be removed from your order. This action cannot be undone.`,
        size: 'sm',
        footerButtonProps: [
          {
            children: 'Confirm',
            onClick: () => {
              setBasicNotification(
                `${cart[index].template.name} has been removed from your order.`
              )

              // hide preview if active template is getting removed
              if (cart[index].template.id === activeTemplate?.id) {
                setActiveTemplate(null)
              }

              setCart((cart) => {
                const newCart = [...cart]
                newCart.splice(index, 1)
                return newCart
              })

              setModalOpen(false)
            },
            color: 'primary',
            variant: 'contained',
          },
          {
            children: 'Go Back',
            onClick: () => setModalOpen(false),
            color: 'primary',
            variant: 'outlined',
          },
        ],
      }

      setModalProps(modalProps)
      setModalOpen(true)
    },
    [setCart, setModalOpen, setModalProps, cart, activeTemplate]
  )

  const generateFormData = useCallback(
    (values, localCart, userValues = {}, isPreview = true) => {
      const formData = new FormData()
      const deliveryType = location.state.activeIndex

      if (values.account_number) {
        formData.append('account_id', values.account_number)
      }

      if (deliveryType === DELIVERY_TYPES.FAX) {
        formData.append('is_fax_delivery', 1)
        formData.append('fax[address]', values.fax_number)
      } else if (deliveryType === DELIVERY_TYPES.MAIL) {
        // mail
        formData.append('is_mail_delivery', 1)
        formData.append('accept_invalid_physical_address', 1)
        formData.append('address[name]', values.name || '')
        formData.append('address[line1]', values.address_line_1 || '')
        formData.append('address[line2]', values.address_line_2 || '')
        formData.append('address[city]', values.city || '')
        formData.append('address[state]', values.state || '')
        formData.append('address[zip]', values.zip_code || '')
        formData.append('address[country]', values.country || '')
      } else if (deliveryType === DELIVERY_TYPES.EMAIL) {
        // email
        formData.append('is_email_delivery', 1)
        formData.append('email[name]', values.name)
        formData.append('email[address]', values.email)
      } else if (deliveryType === DELIVERY_TYPES.CLAIM_DENIAL) {
        formData.append('is_mail_delivery', 1)

        try {
          const addressField = localCart[0].template.fields.find(
            (field) => field.path === 'to_address_client'
          )
          const addressOptions = Object.entries(addressField.options || {})
          let fieldValue =
            userValues[addressField.path] ?? addressField.default_value

          if (fieldValue === addressOptions[0]?.[1] || !fieldValue) {
            fieldValue = addressOptions[1]?.[0]
          }

          fieldValue = addressField.options[fieldValue]

          let address = ''
          let city = ''
          let state = ''
          let zip = ''
          let name = ''

          if (fieldValue) {
            let rest = ''
            ;[address, city, rest] = fieldValue.split(', ')
            const remainingTokens = rest.trim().split(' ')
            ;[state, zip] = remainingTokens
            name = remainingTokens.slice(2).join(' ')
          }

          formData.append('address[name]', name || '')
          formData.append('address[line1]', address || '')
          formData.append('address[line2]', '')
          formData.append('address[city]', city || '')
          formData.append('address[state]', state || '')
          formData.append('address[zip]', zip || '')
          formData.append('address[country]', 'USA')
        } catch (err) {}
      }

      let configuration = {}
      const files = {}

      localCart.forEach((cartItem) => {
        const templateValues = {}
        const fileValues = {}

        cartItem.template.fields.forEach((field) => {
          const fieldValue = userValues[field.path] ?? field.default_value

          if (field.field_type === COLLATERAL_FIELD_TYPES.FILEUPLOAD) {
            fileValues[field.path] = {
              name: fieldValue?.name,
            }
            formData.append(
              `${cartItem.template.id}[${field.path}]`,
              fieldValue
            )
          } else if (field.field_type === COLLATERAL_FIELD_TYPES.CHECKBOX) {
            templateValues[field.path] = fieldValue ? '1' : '0'
          } else if (
            field.field_type === COLLATERAL_FIELD_TYPES.MATERIAL_SELECT_7102
          ) {
            templateValues[field.path] = fieldValue.join(',')
          } else if (field.field_type === COLLATERAL_FIELD_TYPES.MULTISELECT) {
            templateValues[field.path] = fieldValue.join(',')
          } else if (field.field_type === COLLATERAL_FIELD_TYPES.DATE) {
            let dateDisplay = fieldValue
            let date = fieldValue instanceof Date && fieldValue

            if (Array.isArray(fieldValue)) {
              date = fieldValue[0]
            }

            if (date instanceof Date) {
              dateDisplay = format(date, field.date_format || 'MMMM d, yyyy')
            } else {
              dateDisplay = null
            }

            templateValues[field.path] = dateDisplay || ''
          } else if (field.field_type === COLLATERAL_FIELD_TYPES.TIME) {
            let timeDisplay = fieldValue

            if (fieldValue instanceof Date) {
              timeDisplay = format(fieldValue, 'p')
            }

            templateValues[field.path] = timeDisplay || ''
          } else {
            templateValues[field.path] = fieldValue || ''
          }
        })

        if (isPreview) {
          configuration = templateValues
        } else {
          configuration[cartItem.template.id] = templateValues
        }

        if (!isEmptyObject(fileValues)) files[cartItem.template.id] = fileValues
      })

      formData.append('configuration', JSON.stringify(configuration))

      if (!isEmptyObject(files)) formData.append('files', JSON.stringify(files))

      return formData
    },
    [location]
  )

  const generatePdfValues = useCallback(
    (userValues) => {
      let values = [...activeTemplate.fields]

      if (Object.keys(userValues).length > 0)
        values = values.map((field) => ({
          ...field,
          default_value: userValues[field.path] || field.default_value,
        }))

      return values
    },
    [activeTemplate]
  )

  const handleBlur = useCallback(
    (userValues = {}) => {
      const values = generatePdfValues(userValues)

      if (deepEqual(values, pdfValues)) return

      const formData = generateFormData(
        location.state.values,
        [{ template: activeTemplate }],
        userValues
      )

      setPdfValues(values)
      setPreviewFormData(formData)
    },
    [
      activeTemplate,
      pdfLoading,
      location,
      setPdfLoading,
      pdfValues,
      generatePdfValues,
    ]
  )

  const handleChange = useCallback(
    (params) => {
      clearTimeout(previewFormTimeout)

      if (params?.userValues) {
        setPdfLoading(true)

        // generate from passed values as normal
        // blur handling has been skipped, so the state var
        // is not up-to-date with the form
        const formData = generateFormData(
          location.state.values,
          [{ template: activeTemplate }],
          params.userValues
        )

        fetchPreviewPdf(formData, activeTemplate)

        // block timeout to avoid duplicate refresh on next render
        blockTimeout.current = true
        setPreviewFormData(formData)
        setPdfValues(generatePdfValues(params.userValues))
      }
    },
    [previewFormTimeout, blockTimeout, generateFormData, activeTemplate]
  )

  const hideList = mode === COLLATERAL_MODES.STANDALONE

  useEffect(() => {
    // one-render blocker
    if (blockTimeout.current) {
      blockTimeout.current = false
      return
    }

    setPreviewFormTimeout(
      setTimeout(() => {
        // only fetch a new preview if the previous is done loading
        if (
          previewFormData &&
          activeTemplate?.should_render_preview &&
          !blockTimeout.current
        ) {
          setPdfLoading(true)
          fetchPreviewPdf(previewFormData, activeTemplate)
        }
      }, 3000)
    )

    return () => clearTimeout(previewFormTimeout)
  }, [previewFormData, blockTimeout])

  // load pdf on template change
  useEffect(() => {
    clearTimeout(previewFormTimeout)

    if (activeTemplate?.should_render_preview) {
      setPdfLoading(true)

      const formData = generateFormData(location.state.values, [
        { template: activeTemplate },
      ])

      fetchPreviewPdf(formData, activeTemplate)
    }
  }, [activeTemplate])

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          padding: 8,
          minWidth: '1200px',
          minHeight: '600px',
        }}
      >
        <Card
          sx={{ height: '100%', width: '100%', boxShadow: 2, display: 'flex' }}
        >
          {!hideList && (
            <Box sx={{ width: '280px' }}>
              <Card
                sx={{
                  boxShadow: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    height: '40px',
                    pl: 6,
                    pt: 3,
                    boxShadow: 1,
                  }}
                >
                  <Typography fontSize="16px" fontWeight="bold">
                    COLLATERAL TYPE
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: '50%',
                    boxShadow: 1,
                  }}
                >
                  <Categories
                    activeTemplate={activeTemplate}
                    setActiveTemplate={setActiveTemplate}
                    cart={cart}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    maxHeight: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      height: '56px',
                      pl: 6,
                      pt: 6,
                      boxShadow: 1,
                    }}
                  >
                    <Typography fontSize="16px" fontWeight="bold">
                      ORDER
                    </Typography>
                  </Box>
                  <OrderList
                    cart={cart}
                    removeFromCart={removeFromCart}
                    activeTemplate={activeTemplate}
                    setActiveTemplate={setActiveTemplate}
                  />
                </Box>
                <Box sx={{ height: '72px', pt: 4, px: 4 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="action-header"
                    sx={{ float: 'right' }}
                    disabled={cart.length === 0}
                    onClick={() => setShowOrderModal(true)}
                  >
                    Complete
                  </Button>
                </Box>
              </Card>
            </Box>
          )}
          <Box
            sx={{
              flex: 1,
              bgcolor: 'white',
              py: 4,
              px: 6,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {activeTemplate && (
              <ActiveTemplatePreview
                cart={cart}
                addToCart={addToCart}
                handleBlur={handleBlur}
                handleChange={handleChange}
                previewFormData={previewFormData}
                previewFormTimeout={previewFormTimeout}
                generateFormData={generateFormData}
                parseCart={parseCart}
              />
            )}
            {!activeTemplate && !hideList && (
              <Typography
                fontSize={14}
                color="darkgray.light"
                sx={{ m: 'auto' }}
              >
                Please select a template from the left sidebar - Collateral
                type.
              </Typography>
            )}
          </Box>
        </Card>
      </Box>
      <OrderSummaryModal
        cart={cart}
        open={showOrderModal}
        setOpen={setShowOrderModal}
        removeFromCart={removeFromCart}
        generateFormData={generateFormData}
      />
    </>
  )
}

export default ItemsOrderList
