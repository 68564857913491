import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { Formik, useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'
import get from 'lodash.get'

import Modal from 'components/common/Modal'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import PlusIcon from '@mui/icons-material/Add'

import OrderedItems from './OrderedItems'
import AddressForm, { optionalKeys, validationSchemas } from '../AddressForm'

import useConfiguration from 'hooks/useConfiguration'
import useOrderSummary from 'hooks/collateral/useOrderSummary'
import {
  COLLATERAL_ENABLE_ACCOUNT_NUMBER,
  COLLATERAL_MULTIPLE_RECIPIENTS,
  COLLATERAL_OPTIONAL_ACCOUNT_NUMBER,
  DELIVERY_TYPES,
} from 'utils/constants'

const initialValues = [
  [
    {
      account_number: '',
      name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: 'USA',
      language: '',
    },
  ],
  [
    {
      account_number: '',
      name: '',
      email: '',
    },
  ],
]

function DeliveryAddressForm({ activeIndex, setFooterActionProps, setOpen }) {
  const { values, setValues, dirty, errors, submitForm } = useFormikContext()
  const { instanceConfigurations, currentModule } = useConfiguration()
  const excludeAccountNumber =
    instanceConfigurations?.[COLLATERAL_ENABLE_ACCOUNT_NUMBER] !== '1'
  const requireAccountNumber =
    instanceConfigurations?.[COLLATERAL_OPTIONAL_ACCOUNT_NUMBER] !== '1'
  const allowMultipleRecipients =
    instanceConfigurations?.[COLLATERAL_MULTIPLE_RECIPIENTS] === '1'
  const bypassAccountCheck = useMemo(() => {
    return get(
      currentModule || {},
      'configurations.0.settings.account_optional'
    )
  }, [currentModule])

  const nextDisabled = useMemo(() => {
    if (errors && Object.keys(errors).length > 0) return true

    const valuesEntries = Object.entries(values)

    for (let i = 0; i < valuesEntries.length; i++) {
      const [key, value] = valuesEntries[i]

      if (!optionalKeys.includes(key) && !value) return true
    }

    return false
  }, [dirty, errors, values])

  useEffect(() => {
    setFooterActionProps([
      {
        children: 'Submit',
        color: 'primary',
        variant: 'contained',
        onClick: () => submitForm(),
        disabled: nextDisabled,
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: () => setOpen(false),
      },
    ])
  }, [nextDisabled, setOpen, submitForm, setFooterActionProps])

  const addressForm = useMemo(() => {
    return (
      <>
        {values.map((_, index) => (
          <Box key={index}>
            {Boolean(index) && (
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', py: 6 }}
              >
                <Typography fontWeight={600} variant="h3" my="auto">
                  Address #{index + 1}
                </Typography>
                <Button
                  size="small"
                  type="button"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    const vals = [...values]

                    vals.splice(index, 1)

                    setValues(vals)
                  }}
                >
                  Remove recipient
                </Button>
              </Box>
            )}
            <AddressForm
              index={index}
              activeIndex={activeIndex}
              excludeAccountNumber={excludeAccountNumber}
              bypassAccountCheck={bypassAccountCheck}
              requireAccountNumber={requireAccountNumber}
            />
          </Box>
        ))}
        {allowMultipleRecipients && (
          <Button
            variant="outlined"
            sx={{ mt: 6 }}
            startIcon={<PlusIcon />}
            onClick={() => {
              setValues([...values, initialValues[activeIndex][0]])
            }}
          >
            Add CC Recipient
          </Button>
        )}
      </>
    )
  }, [
    activeIndex,
    excludeAccountNumber,
    bypassAccountCheck,
    requireAccountNumber,
    values,
  ])

  return addressForm
}

function OrderSummaryModal({
  cart,
  open,
  setOpen,
  removeFromCart,
  generateFormData,
}) {
  const location = useLocation()
  const [footerActionProps, setFooterActionProps] = useState([])
  const { handleSubmit } = useOrderSummary({ generateFormData })
  const { instanceConfigurations } = useConfiguration()
  const validate = useCallback(
    (values) => {
      const activeIndex = location.state.activeIndex
      const errors = {}

      const requireAccountNumber =
        instanceConfigurations?.[COLLATERAL_OPTIONAL_ACCOUNT_NUMBER] !== '1'

      if (requireAccountNumber && activeIndex < 2) {
        values.forEach((val, index) => {
          if (!val.account_number) {
            errors[index] = {
              account_number: 'Account number is required',
            }
          }
        })
      }

      return {}
    },
    [instanceConfigurations, location]
  )

  const fieldsTitle = useMemo(() => {
    switch (location.state.activeIndex) {
      default:
        return ''
      case DELIVERY_TYPES.MAIL:
        return 'Mail delivery details'
      case DELIVERY_TYPES.EMAIL:
        return 'Email delivery details'
      case DELIVERY_TYPES.FAX:
        return 'Fax delivery details'
    }
  }, [location.state.activeIndex])

  const handleFormSubmit = useCallback(
    (values) => {
      handleSubmit(cart, values)
    },
    [cart, handleSubmit]
  )

  useEffect(() => {
    if (open && cart.length === 0) {
      setOpen(false)
    }
  }, [cart])

  return (
    <Modal
      width="1144px"
      height="864px"
      title="Order Summary"
      open={open}
      onClose={() => setOpen(false)}
      footerButtonProps={footerActionProps}
      hideButtonsBorder
    >
      <OrderedItems
        title="Ordered items"
        items={cart}
        handleRemove={removeFromCart}
      />
      <Typography variant="h2" fontWeight="medium" my={6}>
        {fieldsTitle}
      </Typography>
      <Formik
        initialValues={
          location.state.values
            ? [location.state.values, ...(location.state.recipients || [])]
            : undefined
        }
        enableReinitialize
        onSubmit={handleFormSubmit}
        validationSchema={validationSchemas[location.state.activeIndex]}
        validateOnBlur={true}
        validate={validate}
      >
        <DeliveryAddressForm
          activeIndex={location.state.activeIndex}
          setFooterActionProps={setFooterActionProps}
          setOpen={setOpen}
        />
      </Formik>
    </Modal>
  )
}

export default OrderSummaryModal
