import React, { useState, useEffect, useMemo } from 'react'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import SearchField from 'components/common/SearchField'
import AddPrintAssetModal from 'components/print_assets/AddModal'
import FileTypeButton from './FileTypeButton'
import AssetsTable from './AssetsTable'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import { usePrintAssets } from 'hooks/print_assets'
import useConfiguration from 'hooks/useConfiguration'
import { PRIVILEGES, PRINT_ASSET_MIME_TYPES } from 'utils/constants'
import useInstance from 'hooks/instance/useInstance'

function DynamicFileTypeButton({
  type,
  label,
  mimeType,
  onClick,
  total,
  tabIndex,
}) {
  const { total: count, mutate } = usePrintAssets({
    versiondocmimetype: mimeType?.trim(),
    'paging[page]': 1,
    internal: tabIndex === 1 ? 1 : 0,
  })

  useEffect(() => {
    mutate()
  }, [total])

  return (
    <FileTypeButton
      type={type}
      label={label}
      count={count}
      onClick={() => onClick?.(mimeType)}
    />
  )
}

function PrintAssetsResultsList({ tabIndex }) {
  const { hasPrivilege } = useConfiguration()
  const { appendPrintAssetColumns, getPrintAssetDefaultSort } = useInstance()
  const [showAddModal, setShowAddModal] = useState(false)
  const { setTotal, setTotalPages, page, pageSize } = useDataListContext()
  const [name, setName] = useState('')
  const [documentId, setDocumentId] = useState('')
  const [filters, setFilters] = useState({})
  const [sortList, setSortList] = useState(getPrintAssetDefaultSort())
  const [clientLoading, setClientLoading] = useState(false)

  const hookParams = useMemo(() => {
    const params = {
      ...sortList,
      embed: 'versions',
      'paging[page]': page,
      'paging[page_size]': pageSize,
      internal: tabIndex === 1,
    }

    if (filters.name) {
      params['name'] = filters.name
      params['name_like'] = 1
    }

    if (filters.versiondocid) {
      params['versiondocid'] = filters.versiondocid
    }

    if (filters.versiondocmimetype) {
      params['versiondocmimetype'] = filters.versiondocmimetype
    }

    return params
  }, [sortList, filters, page, pageSize, tabIndex])

  const { assets, total, totalPages, loading, addPrintAsset, mutate } =
    usePrintAssets(hookParams)

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages)
    }
  }, [total])

  useEffect(() => {
    if (totalPages !== undefined) {
      setTotalPages(totalPages)
    }
  }, [totalPages])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: assets,
    presetName: 'printAssets',
    setSortList,
  })

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const newFilters = { ...filters }
    newFilters[name] = value

    setFilters(newFilters)
  }

  return (
    <>
      <AddPrintAssetModal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        addPrintAsset={addPrintAsset}
        tabIndex={tabIndex}
      />

      <Box
        sx={{
          height: '80px',
          display: 'flex',
          justifyContent: 'space-between',
          pb: 7.5,
          pt: 2.5,
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 600,
            lineHeight: '32px',
            my: 'auto',
          }}
        >
          Assets
        </Typography>
        {hasPrivilege(PRIVILEGES.CREATE_PRINT_ASSET) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowAddModal(true)}
            size="large"
          >
            Create New Asset
          </Button>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '& > *:not(:last-child)': {
            mr: 8,
          },
        }}
      >
        {['JPEG', 'PNG', 'PDF'].map((mimeTypeKey) => {
          return (
            <Box sx={{ mb: 8, flex: 1 }}>
              <DynamicFileTypeButton
                tabIndex={tabIndex}
                total={total}
                type={mimeTypeKey}
                label={mimeTypeKey}
                mimeType={PRINT_ASSET_MIME_TYPES[mimeTypeKey]}
                onClick={(mimeType) => {
                  handleFilterChange({
                    target: {
                      name: 'versiondocmimetype',
                      value: mimeType,
                    },
                  })
                }}
              />
            </Box>
          )
        })}
        <Box sx={{ mb: 8, flex: 1 }}>
          <DynamicFileTypeButton
            tabIndex={tabIndex}
            total={total}
            type={
              <>
                TXT
                <br />
                RTF
              </>
            }
            label="Text files"
            mimeType="text/*"
            onClick={() => {
              handleFilterChange({
                target: {
                  name: 'versiondocmimetype',
                  value: 'text/*',
                },
              })
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          '& > *': { display: 'flex' },
          '& > * > *': { my: 'auto' },
          mb: 2,
          pb: 2,
          borderBottom: '1px solid',
          borderColor: 'lightgray.main',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <TextField
            fullWidth
            select
            label="File type"
            placeholder="File type"
            size="small"
            sx={{ mr: 8 }}
            name="versiondocmimetype"
            value={filters.versiondocmimetype || ''}
            onChange={handleFilterChange}
          >
            <MenuItem value="" selected={filters.versiondocmimetype === ''}>
              Any
            </MenuItem>
            {Object.entries(PRINT_ASSET_MIME_TYPES)
              .filter(([key]) => !['DOCX', 'EPS', 'AI'].includes(key))
              .map(([key, mimeType]) => (
                <MenuItem
                  value={mimeType}
                  selected={filters.versiondocmimetype === mimeType}
                >
                  {key}
                </MenuItem>
              ))}
          </TextField>
          <SearchField
            fullWidth
            size="small"
            placeholder="Name"
            name="name"
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            onSearch={(val) =>
              handleFilterChange({
                target: { name: 'name', value: val ?? name },
              })
            }
            sx={{ mr: 8 }}
          />
          <SearchField
            fullWidth
            size="small"
            placeholder="Document ID"
            name="versiondocid"
            value={documentId || ''}
            onChange={(e) => setDocumentId(e.target.value)}
            onSearch={(val) =>
              handleFilterChange({
                target: { name: 'versiondocid', value: val ?? documentId },
              })
            }
          />
        </Box>
      </Box>

      <Box sx={{ minHeight: '400px' }}>
        {!total && !loading ? (
          <Box sx={{ px: 4, pt: 2, textAlign: 'center' }}>
            <Typography>Filter results empty</Typography>
          </Box>
        ) : (
          <AssetsTable
            dataList={dataList}
            columns={appendPrintAssetColumns(columns)}
            sortList={sortList}
            setSortList={setSortList}
            loading={loading || clientLoading}
            setClientLoading={setClientLoading}
            refetch={mutate}
            tabIndex={tabIndex}
            {...dataListProps}
          />
        )}
      </Box>
    </>
  )
}

export default PrintAssetsResultsList
