import React, { useState, useMemo, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'

import EditIcon from '@mui/icons-material/Edit'

import { usePrintAsset } from 'hooks/print_assets'
import useModal from 'hooks/context/useModal'
import useConfiguration from 'hooks/useConfiguration'
import useNotification from 'hooks/context/useNotification'

import VersionsTable from 'components/print_assets/VersionsTable'
import EditFieldModal from 'components/print_assets/EditFieldModal'

import { PRIVILEGES } from 'utils/constants'
import formatTimeZone from 'helpers/datetime/formatTimeZone'
import UploadNewVersionModal from 'components/print_assets/UploadNewVersionModal'
import PreviewSquare from 'components/print_assets/PreviewSquare'
import useAuth from 'hooks/useAuth'

function HeaderLayoutButtons({ onDeleteModalSubmit, onCheckboxClick, asset }) {
  const { hasPrivilege } = useConfiguration()
  const navigate = useNavigate()
  const { setBasicNotification, setError } = useNotification()
  const { setOpen: setModalOpen, setModalProps } = useModal()

  const handleDeleteClick = () => {
    const modalProps = {
      title: `Delete Asset`,
      children: 'Are you sure you want to permanently delete this file?',
      subTitle: undefined,
      scrollable: false,
      width: '414px',
      height: '224px',
      hideButtonsBorder: true,
      footerButtonProps: [
        {
          children: 'Delete',
          color: 'primary',
          variant: 'contained',
          size: 'action-header',
          onClick: async () => {
            try {
              await onDeleteModalSubmit()
              setModalOpen(false)
              setBasicNotification('Print asset has been successfully deleted')
              navigate('/correspondence-hub/print-asset')
            } catch (err) {
              setModalOpen(false)
              setError(err?.response?.data?.display_message || err?.message)
            }
          },
        },
        {
          children: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          onClick: () => setModalOpen(false),
          size: 'action-header',
        },
      ],
    }

    setModalProps(modalProps)
    setModalOpen(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        '& > .MuiButton-root': { my: 'auto' },
        pr: 8,
      }}
    >
      {hasPrivilege(PRIVILEGES.EDIT_PRINT_ASSET) && (
        <Box sx={{ my: 'auto' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={onCheckboxClick}
                  checked={Boolean(asset?.collateral_available)}
                />
              }
              label="Available for collateral"
            />
          </FormGroup>
        </Box>
      )}
      {hasPrivilege(PRIVILEGES.DELETE_PRINT_ASSET) && (
        <Button
          size="action-header"
          color="primary"
          variant="outlined"
          onClick={handleDeleteClick}
        >
          Delete Asset
        </Button>
      )}
    </Box>
  )
}

const fieldRenderingData = {
  name: {
    field: 'name',
    display: 'Name',
  },
  key: {
    field: 'key_id',
    display: 'Key',
  },
  tags: {
    field: 'custom_data',
    display: 'Tags',
    data_type: 'tags',
  },
  notes: {
    field: 'note',
    display: 'Notes',
    inputProps: {
      multiline: true,
      rows: 4,
    },
  },
}

function DetailsItem({ label, value, setEditableData, variant = 'text' }) {
  const { hasPrivilege } = useConfiguration()
  const { user } = useAuth()
  const editableByMPXAdmin = ['Name']

  const renderValue = useMemo(() => {
    const getValueDisplay = (val) => {
      if (typeof val === 'object') {
        return JSON.stringify(val)
      }

      return val?.toString() || ''
    }

    let textValue = value?.toString()

    if (variant === 'tags') {
      // value should be of the form [{ name: '', value: '' }, ...]
      if (Array.isArray(value) && value.length > 0) {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {value
              .filter((tag) => tag.value)
              .map((tag) => (
                <Chip
                  label={`${tag.name}: ${getValueDisplay(tag.value)}`}
                  sx={{
                    mb: 2,
                    mr: 2,
                    fontSize: '16px',
                  }}
                />
              ))}
          </Box>
        )
      } else {
        textValue = 'N/A'
      }
    }
    return (
      <Typography
        lineHeight="24px"
        whitespace="pre"
        sx={{ wordBreak: 'break-word' }}
      >
        {textValue || 'N/A'}
      </Typography>
    )
  })

  const handleEditClick = () => {
    setEditableData(fieldRenderingData[label.toLowerCase()])
  }

  const isEditable = () => {
    let editable =
      Boolean(setEditableData) && hasPrivilege(PRIVILEGES.EDIT_PRINT_ASSET)

    if (editableByMPXAdmin.includes(label)) {
      editable &&= user.isMPXAdmin
    }

    return editable
  }

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Typography fontWeight={700} my="auto" lineHeight="24px">
          {label}
        </Typography>
        {isEditable() && (
          <IconButton onClick={handleEditClick} color="primary" size="small">
            <EditIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </Box>
      {renderValue}
    </Box>
  )
}

const isAvailableForCollateral = (asset) => {
  const validMimetypes = [
    'image/png',
    'image/jpeg',
    'image/tiff',
    'application/pdf',
  ]

  if (!validMimetypes.includes(asset?.mime_type)) {
    return false
  }

  return true
}

function PrintAssetPreview() {
  const { setError, setBasicNotification } = useNotification()
  const [editableData, setEditableData] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { hasPrivilege } = useConfiguration()
  const { assetId } = useParams()
  const {
    asset,
    deletePrintAsset,
    updatePrintAsset,
    updatePrintAssetVersion,
    addNewPrintAssetVersion,
    dataLoading,
    addPrintAssetNote,
  } = usePrintAsset({
    assetId,
  })

  const handleAvailableForCollateralToggle = useCallback(async () => {
    if (isAvailableForCollateral(asset)) {
      try {
        await updatePrintAsset({
          id: assetId,
          updateData: {
            collateral_available: asset?.collateral_available ? 0 : 1,
          },
          isForm: false,
        })

        setBasicNotification('Asset successfully updated')
      } catch (err) {
        setError(err?.response?.data?.display_message)
      }
    } else {
      setError(`Asset of type ${asset?.mime_type} is not allowed in collateral`)
    }
  }, [asset])

  return (
    <Box sx={{ backgroundColor: 'white', px: 8, minHeight: '912px' }}>
      <EditFieldModal
        open={Boolean(editableData)}
        setOpen={() => setEditableData(null)}
        fieldData={editableData}
        asset={asset}
        updatePrintAsset={updatePrintAsset}
        updatePrintAssetVersion={updatePrintAssetVersion}
        addPrintAssetNote={addPrintAssetNote}
      />
      <UploadNewVersionModal
        open={showUploadModal}
        setOpen={setShowUploadModal}
        asset={asset}
        addNewPrintAssetVersion={addNewPrintAssetVersion}
      />
      <Box
        sx={{
          height: '102px',
          background: 'white',
          display: 'flex',
          borderColor: 'darkblue.light',
          justifyContent: 'space-between',
          overflowY: 'hidden',
          overflowX: 'auto',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography
          fontSize="32px"
          fontWeight={600}
          lineHeight="43.58px"
          sx={{ my: 'auto' }}
        >
          {asset && `${asset?.name ?? ''} Details`}
        </Typography>
        <HeaderLayoutButtons
          onDeleteModalSubmit={async () => deletePrintAsset({ id: assetId })}
          asset={asset}
          onCheckboxClick={handleAvailableForCollateralToggle}
        />
      </Box>
      <Card
        sx={{
          py: 8,
          px: 8,
          border: '1px solid',
          borderColor: 'lightgray.main',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          columnGap: 8,
        }}
      >
        <Box sx={{ minWidth: 120 }}>
          <PreviewSquare fluid asset={asset} />
        </Box>
        <Grid container columns={11}>
          <Grid item xs={11} sm={5} md={4} lg={3} xl={2}>
            <Stack spacing={6}>
              <DetailsItem
                label="Name"
                value={asset?.name || 'N/A'}
                setEditableData={setEditableData}
              />
              <DetailsItem label="Key" value={assetId || 'N/A'} />
            </Stack>
          </Grid>
          <Grid item xs={11} sm={5} md={4} lg={3} xl={2}>
            <Stack spacing={6}>
              <DetailsItem
                label="Document ID"
                value={asset?.document_id || 'N/A'}
              />
              <DetailsItem
                label="Created by"
                value={asset?.user?.display || 'N/A'}
              />
            </Stack>
          </Grid>
          <Grid item xs={11} sm={6} lg={4} xl={3} sx={{ pt: { xs: 6, lg: 0 } }}>
            <Stack spacing={6}>
              <DetailsItem
                label="Created"
                value={formatTimeZone(asset?.datetime_created)}
              />
              <DetailsItem
                label="Modified"
                value={formatTimeZone(asset?.datetime_modified)}
              />
            </Stack>
          </Grid>
          <Grid item xs={11} sm={5} xl={4} sx={{ pt: { xs: 6, lg: 0 } }}>
            <DetailsItem
              label="Tags"
              variant="tags"
              value={Object.entries(asset?.custom_data || {}).map(
                ([name, value]) => ({ name, value })
              )}
              setEditableData={setEditableData}
            />
          </Grid>
          <Grid item xs={11} xl={8} sx={{ pt: 6 }}>
            <DetailsItem
              label="Notes"
              variant="notes"
              value={asset?._embedded?.notes?.[0]?.note || ''}
              setEditableData={setEditableData}
            />
          </Grid>
        </Grid>
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 8,
          pb: 4,
          borderBottom: '1px solid',
          borderColor: 'lightgray.main',
        }}
      >
        <Typography my="auto" fontWeight={600} fontSize="24px">
          File versions
        </Typography>
        {hasPrivilege(PRIVILEGES.EDIT_PRINT_ASSET) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowUploadModal(true)}
          >
            Upload new version
          </Button>
        )}
      </Box>
      <VersionsTable
        versions={asset?._embedded?.versions?.versions || []}
        activeVersion={asset?.default_version}
        debugVersion={asset?.debug_version}
        updatePrintAssetVersion={updatePrintAssetVersion}
        updatePrintAsset={updatePrintAsset}
        dataLoading={dataLoading}
      />
    </Box>
  )
}

export default PrintAssetPreview
