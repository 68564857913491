import React, { useRef, useState } from 'react'
import { Formik, useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import AsyncTextField from 'components/common/AsyncTextField'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import AddressAutocomplete from 'components/common/AddressAutocomplete'

import Modal from 'components/common/Modal'
import MenuItem from '@mui/material/MenuItem'

import useNotification from 'hooks/context/useNotification'
import useDocumentInboundPullAddress from 'hooks/digital_mail/useDocumentInboundPullAddress'
import useModal from 'hooks/context/useModal'

import { generateAddressModalProps } from 'utils/smartyStreets'
import isCountryUSA from 'helpers/api/isCountryUSA'
import FileAttachDropzone from './FileAttachDropzone'
import useConfiguration from 'hooks/useConfiguration'
import { PRIVILEGES } from 'utils/constants'

import useInstance from 'hooks/instance/useInstance'

export const addressKeys = {
  address: 'to.line1',
  addressalt: 'to.line2',
  city: 'to.city',
  state: 'to.state',
  country: 'to.country',
  zip: 'to.zip_code',
}

function MailOriginalForm({ addresses }) {
  const [disableAddress, setDisableAddress] = useState(false)
  const savedAddresses = addresses || []
  const [addrStoreMsg, setAddrStoreMsg] = useState(
    'Save address for future usage?'
  )
  const [checked, setChecked] = useState(false)
  const {
    values: {
      to: { name, line2, city, state, zip_code, country },
      instructions,
      addressid,
      attachment,
    },
    handleChange,
    setFieldValue,
    setFieldError,
    errors,
  } = useFormikContext()
  const { hasPrivilege } = useConfiguration()
  const { getMailOriginalCustomAddresses } = useInstance()
  const { nonAddressIds, customAddressIds } = getMailOriginalCustomAddresses()

  const instructionsPrefix =
    nonAddressIds[addressid] || customAddressIds[addressid] || ''

  const sharedProps = {
    fullWidth: true,
    onChange: handleChange,
  }

  const handleAddrChange = (e) => {
    const { value } = e.target

    if (nonAddressIds[value]) {
      setFieldValue('to', {
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
      })
      setFieldValue('instructions', nonAddressIds[value])
      setChecked(false)
      setDisableAddress(true)
      handleChange(e)
    } else {
      let addr = savedAddresses.find((address) => address.id === value)
      setChecked(false)
      setDisableAddress(false)
      handleChange(e)
      setFieldValue('instructions', '')
      if (value !== '0') {
        // don't clear if defaulted
        setAddrStoreMsg('Save as new address?')
        setFieldValue('to', {
          name: addr?.name || '',
          line1: addr?.line1 || '',
          line2: addr?.line2 || '',
          city: addr?.city || '',
          state: addr?.state || '',
          zip_code: addr?.zip || '',
          country: !isCountryUSA(addr?.country)
            ? addr?.country || 'USA'
            : 'USA',
        })
      } else {
        setFieldValue('to', {
          name: '',
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip_code: '',
          country: 'USA',
        })
        setAddrStoreMsg('Save address for future usage?')
      }

      if (customAddressIds[value]) {
        setFieldValue('instructions', customAddressIds[value])
      }
    }
  }

  const handleInstructionsChange = (e) => {
    let instruction = e.target.value

    if (instructionsPrefix) {
      instruction = instruction.slice(instructionsPrefix.length)
    }

    handleChange({
      target: {
        name: e.target.name,
        value: `${instructionsPrefix}${instruction}`,
      },
    })
  }

  return (
    <Grid container spacing={4}>
      {Array.isArray(savedAddresses) && savedAddresses.length > 0 ? (
        <Grid item xs={12}>
          <AsyncTextField
            name="addressid"
            label="Address"
            fullWidth={true}
            defaultValue={'0'}
            value={addressid}
            onChange={handleAddrChange}
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: { maxHeight: '50vh !important', boxShadow: 2 },
                },
              },
            }}
          >
            <MenuItem value={'0'}>Custom Address</MenuItem>
            {Object.keys(customAddressIds).map((key) => (
              <MenuItem key={key} value={key}>
                {customAddressIds[key]}
              </MenuItem>
            ))}
            {Object.keys(nonAddressIds).map((key) => (
              <MenuItem key={key} value={key}>
                {nonAddressIds[key]}
              </MenuItem>
            ))}
            {savedAddresses
              .slice()
              .sort((a, b) =>
                a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase())
              )
              .map((address) => (
                <MenuItem key={address.id} value={address.id}>
                  {address.name} - {address.line1}
                </MenuItem>
              ))}
          </AsyncTextField>
        </Grid>
      ) : null}
      {!disableAddress && (
        <>
          <Grid item xs={6}>
            <TextField
              {...sharedProps}
              required={true}
              name="to.name"
              label="Name"
              value={name}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...sharedProps}
              required={true}
              name="to.country"
              label="Country"
              value={country}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressAutocomplete
              addressKeys={addressKeys}
              textFieldProps={{
                ...sharedProps,
                required: true,
                name: 'to.line1',
                label: 'Address line 1',
                disabled: disableAddress,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...sharedProps}
              name="to.line2"
              label="Address line 2"
              value={line2}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...sharedProps}
              name="to.city"
              label="City"
              value={city}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...sharedProps}
              required={true}
              name="to.state"
              label="State / Province"
              value={state}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...sharedProps}
              required={true}
              name="to.zip_code"
              label="ZIP / Postal Code"
              value={zip_code}
              disabled={disableAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="saveAddress"
                  value="1"
                  onClick={() => setChecked(!checked)}
                  checked={checked}
                  onChange={handleChange}
                  disabled={disableAddress}
                />
              }
              label={addrStoreMsg}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TextField
          {...sharedProps}
          multiline
          placeholder="Add instructions"
          name="instructions"
          rows={7}
          sx={{ height: '100%' }}
          variant="filled"
          value={instructions}
          onChange={handleInstructionsChange}
          InputProps={{
            readOnly: Boolean(instructionsPrefix),
          }}
        />
      </Grid>
      {hasPrivilege(PRIVILEGES.ATTACH_MAIL_ORIGINAL_DOCUMENT) && (
        <Grid item xs={12}>
          <FileAttachDropzone
            display="Attachment"
            file={attachment}
            onChange={(file) => setFieldValue('attachment', file)}
            setFieldError={setFieldError}
            error={errors.attachment}
          />
        </Grid>
      )}
    </Grid>
  )
}

function MailOriginalModal({ open, onClose, mailOriginalItem }) {
  const formikRef = useRef(null)
  const { setError, setBasicNotification } = useNotification()
  let isSubmitting = false
  const { addresses, mutate } = useDocumentInboundPullAddress()
  const { setModalProps, setOpen } = useModal()
  const { getMailOriginalCustomAddresses } = useInstance()
  const { nonAddressIds, customAddressIds } = getMailOriginalCustomAddresses()

  const initialValues = {
    to: {
      name: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip_code: '',
      country: 'USA',
    },
    saveAddress: false,
    instructions: '',
    addressid: '0',
    attachment: null,
  }

  const handleSubmit = async (values) => {
    const onCancel = () => {
      setOpen(false)
      isSubmitting = false
    }
    const onContinue = async (newValues) => {
      if (Array.isArray(newValues)) {
        if (newValues.length !== 1) {
          setError(
            'Cannot request mail original to more than on address, there is only one original'
          )
          onCancel()
          return
        }

        newValues = newValues[0]
      }

      const sendValues = { ...newValues }
      if (isSubmitting === (isSubmitting = true)) {
        return
      }

      if (customAddressIds[`${values.addressid}`]) {
        sendValues.addressid = '0'
      }

      try {
        await mailOriginalItem(sendValues)
      } catch (err) {
        setError(err.response?.data?.display_message || err.message)
        onCancel()
        return
      }

      if (values.saveAddress) {
        mutate()
      }
      setBasicNotification('Original document has been requested to be sent.')
      setOpen(false)
      onClose()
      isSubmitting = false
    }

    try {
      if (!nonAddressIds[`${values.addressid}`]) {
        let errors = []
        if ((values.to.name || '').trim().length === 0) {
          errors.push('Name is required')
        }
        if ((values.to.line1 || '').trim().length === 0) {
          errors.push('Address Line 1 is required')
        }
        if ((values.to.city || '').trim().length === 0) {
          errors.push('City is required')
        }
        if ((values.to.state || '').trim().length === 0) {
          errors.push('State is required')
        }
        if ((values.to.zip_code || '').trim().length === 0) {
          errors.push('Zip is required')
        }
        if ((values.to.country || '').trim().length === 0) {
          errors.push('Country is required')
        }

        if (errors.length > 0) {
          setError(errors.join(', '))
          return false
        }

        const { modalProps } = await generateAddressModalProps({
          values,
          addressKeys,
          onContinue,
          onCancel,
        })

        setModalProps(modalProps)
        setOpen(true)
      } else {
        onContinue(values)
      }
    } catch (err) {
      setError(err.response?.data?.display_message || err.message)
    }
  }

  const modalProps = {
    title: 'Mail Original Document',
    subTitle: undefined,
    scrollable: true,
    size: undefined,
    height: '604px',
    width: '732px',
    hideButtonsBorder: true,
    footerButtonProps: [
      {
        children: 'Save',
        color: 'primary',
        variant: 'contained',
        size: 'action-header',
        onClick: () => formikRef.current.submitForm(),
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: () => onClose(),
        size: 'action-header',
      },
    ],
  }

  return (
    <Modal {...modalProps} open={open} onClose={onClose}>
      <Box sx={{ pt: 2 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <MailOriginalForm addresses={addresses} />
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  )
}

export default MailOriginalModal
